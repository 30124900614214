import {Stack, 
  AppBar, 
  Toolbar, 
  Typography, 
  IconButton, 
  Box, 
  Drawer, 
  FormControl, 
  Select, 
  MenuItem,
  InputLabel,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import GitHubIcon from '@mui/icons-material/GitHub';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { setVersion, setLanguage } from "../accessListSlice";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


export default function Navbar(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  let version = useSelector(state => state.version);
  const dispatch = useDispatch();

  const toggleDrawer = open => event => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    //changes the function state according to the value of open
    setOpen(open);
  };
  const handleJavascriptChange = (value) => {
    dispatch(setVersion(value))
    dispatch(setLanguage("javascript"))
  }
  const followLink = e => {
    setOpen(false);
    navigate(e.target.value);
  };

  return (
    <AppBar
      position="absolute"
      sx={{
        zIndex: theme => theme.zIndex.drawer + 10,
        color: "black",
        backgroundColor: "rgba(255,255,255,0.0)",
        boxShadow: "none"
      }}
    >
      <Toolbar>
      
        <Typography
          variant="h5"
          display="flex"
          justifyContent="center"
          sx={{
            flexGrow: "1",
            fontWeight: 700,
            paddingLeft: ".5em",
            paddingTop: ".5em",
            color: "black",
            textDecoration: "none",
            
          }}
        >
            <svg id="eIiWdxe5Ru01" xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 100 100" 
                height="70" width="70"
                shape-rendering="geometricPrecision" 
                text-rendering="geometricPrecision">
                    <line x1="-0.078575" y1="-13.881613" x2="0.078575" y2="13.881614" transform="translate(46.359351 32.739655)" style={{fill:"none", stroke:"#3f5787"}}/>
                    <line x1="11.917235" y1="-6.416972" x2="-11.917234" y2="6.416973" transform="translate(34.363542 53.038241)" style={{fill:"none", stroke:"#3f5787"}}/>
                    <line x1="0.078575" y1="-18.465165" x2="-0.078575" y2="18.465165" transform="translate(46.359351 65.086433)" style={{fill:"none", stroke:"#3f5787"}}/>
                    <line x1="-12.886328" y1="-6.416973" x2="12.886328" y2="6.416973" transform="translate(59.324254 53.038241)" style={{fill:"none", stroke:"#3f5787"}}/>
                    <ellipse rx="3.562075" ry="3.666841" transform="translate(46.437926 18.858041)" style={{fill:"#f60", strokeWidth:"0"}}/>
                    <ellipse rx="5.238345" ry="4.976428" transform="translate(22.446307 59.455213)" style={{fill:"#f60", strokeWidth:"0"}}/>
                    <ellipse rx="4.714511" ry="4.976428" transform="translate(72.210582 59.455213)" style={{fill:"#f60", strokeWidth:"0"}}/>
                    <ellipse rx="3.562075" ry="3.404924" transform="translate(46.437926 83.551598)" style={{fill:"#f60", strokeWidth:"0"}}/>
                    <ellipse rx="8.119435" ry="7.857517" transform="translate(46.280776 46.621268)" style={{fill:"#f60", strokeWidth:"0"}}/>
                    <line x1="19.591409" y1="-29.727606" x2="-19.591409" y2="29.727607" transform="translate(26.846517 29.727607)" style={{fill:"none", stroke:"#3f5787"}}/>
                    <line x1="-19.591409" y1="-20.272394" x2="19.591409" y2="20.272393" transform="translate(26.846517 79.727607)" style={{fill:"none", stroke:"#3f5787"}}/>
                    <line x1="-20.220011" y1="-29.727606" x2="20.220011" y2="29.727607" transform="translate(66.657937 29.727607)" style={{fill:"none", stroke:"#3f5787"}}/>
                    <line x1="20.298586" y1="-20.272394" x2="-20.298586" y2="20.272393" transform="translate(66.579362 79.727607)" style={{fill:"none", stroke:"#3f5787"}}/>
            </svg>

          
          <Typography variant="h4" sx={{paddingTop: ".25em",fontWeight: 700,fontFamily: "'Rajdhani', sans-serif", color: "black"}}>Access Tree</Typography>
        </Typography>
        <IconButton
          edge="start"
          color="black"
          aria-label="open drawer"
          onClick={toggleDrawer(true)}
          sx={{
            display: {
              xs: "block",
              color: "black"
            }
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{
              pt: 10,
              height: 1,
              backgroundColor: "white",
              color: "black"
            }}
          >
            <IconButton sx={{ pb: ".5em", color: "black", pr: ".5em" }}>
              <CloseIcon onClick={toggleDrawer(false)} />
            </IconButton>
            <Stack sx={{p:"2em"}}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>Javascript - access-list-express</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{p: ".5em"}}>
                    <FormControl variant="standard" sx={{minWidth: 120, color: "black"}} fullWidth>
                      <InputLabel>Version</InputLabel>
                      <Select
                        labelId="select-version"
                        id="select-version"
                        value={version}
                        label="Version"
                        onChange={(e)=>handleJavascriptChange(e.target.value)}
                      >
                        <MenuItem value={"0.0.1"}>0.0.1</MenuItem>
                        <MenuItem value={"0.0.2"}>0.0.2</MenuItem>
                        <MenuItem value={"0.0.2"}>0.0.3</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Box sx={{padding: "1em", textAlign: "center"}}>
                <Link to="https://github.com/access-tree"><GitHubIcon fontSize="large" style={{color: "black"}}/></Link>
              </Box>
            </Stack>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}