import { createSlice } from '@reduxjs/toolkit'

const accessListSlice = createSlice({
  name: 'accessList',
  initialState: {
    version: "0.0.1",
    language: "javascript"
  },
  reducers: {
    setVersion: (state, action) => {
      state.version = action.payload
    },
    setLanguage: (state, action) => {
      state.language = action.payload
    }
  }
})
export const { setVersion, setLanguage } = accessListSlice.actions
export default accessListSlice