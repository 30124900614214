import { createBrowserRouter, Navigate } from "react-router-dom";
import Home from "./views/Home";
import Welcome from "./components/Welcome";
import Quickstart from "./components/Quickstart";
import Docs from "./components/Docs";
import Examples from "./components/Examples";

const router = createBrowserRouter([
  {
    path: "/",
    exact: true,
    element: <Home />,
    children: [
      {
        index: true,
        element: <Navigate to="/welcome" replace />
      },
      {
        path: "/welcome",
        element: <Welcome />
      },
      {
        path: "/quickstart",
        element: <Quickstart />
      },
      {
        path: "/docs",
        element: <Docs /> 
      },
      {
        path: "/examples",
        element: <Examples />
      }
    ]
  },
  {
    path: "/*",
    element: <Home />
  }
]);
export { router };