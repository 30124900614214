import {useState, useEffect} from 'react';
import Navbar from "../components/Navbar";
import { Container, Box } from "@mui/material";
import Stack from '@mui/material/Stack';
import { isMobile } from "react-device-detect";
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

export default function Home() {

  const navigate = useNavigate();
  useEffect(()=>{
    navigate("/welcome")
  },[])
  return (
    <>
    
    <Navbar/>
    <Container>
        <Box sx={{ mt: "6em", pl: "2em", pr: "2em"}}>  
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb="1em"
          >
            
            <Stack direction="row">
              
              <Box sx={{p:"1em"}}><NavLink  style={{color:"black", textDecoration: "none"}} to="/welcome">Introduction</NavLink></Box>
              <Box sx={{p:"1em"}}><NavLink style={{color:"black", textDecoration: "none"}} to="/quickstart">Quickstart</NavLink></Box>
              <Box sx={{p:"1em"}}><NavLink style={{color:"black", textDecoration: "none"}} to="/docs">API</NavLink></Box>
              <Box sx={{p:"1em"}}><NavLink style={{color:"black", textDecoration: "none"}} to="/examples">Examples</NavLink></Box>
           
          
            </Stack>
           
          </Box>
          <Container>
            <Box sx={{width: "100%", height: "100%", border: "1px solid #3f5787", marginTop: "1em", backgroundColor: "rgba(255,255,255,0.65)"}}>
              <Outlet/>
            </Box>
          </Container>
        </Box>
    </Container>
    </>
  )
}