import {useState, useEffect} from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Content from './Content';

export default function Welcome() {
  let [text, setText] = useState({});
  const version = useSelector(state => state.version);
  const language = useSelector(state => state.language);
  useEffect(()=>{
    axios.get(`${process.env.REACT_APP_BACKEND}/api/introduction/${language}/${version}`)
    .then(d=>{
      setText(d.data.message)
    })
    .catch(e=>{
      console.log(e);
    })
  },[])
  return (
    <>
      <Content text={text}/>
    </>
  )
}