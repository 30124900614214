import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { Box, Typography } from "@mui/material";
import {useState, useEffect} from 'react';

export default function Content(textProp) {
    let [showContent, setShowContent] = useState(false);
    useEffect(()=>{
        console.log(textProp.text);
        if (textProp.text !== null){
          if ("name" in textProp.text) {
              if (textProp.text.name.length > 0 && 
                  textProp.text.name !== null && 
                  textProp.text.name !== undefined
                  )
              {
                  setShowContent(true);
              } else {
                  setShowContent(false);
              }
          }
        }
        
    },[textProp])
    return (
        <>
            {showContent && (
                <>
                <Typography
                variant="h4"
                sx={{
                  mt: "",
                  fontFamily: "'Rajdhani', sans-serif",
                  color: "black",
                  textAlign: "center"
                }}
                style={{ fontFamily: "'Rajdhani': sans-serif" }}
                >
                {textProp.text.name}
                </Typography>
                {textProp.text.content.map(t=>{
                 if(t.label === "p") {
                    return (
                      <Box sx={{ fontFamily: "'Dosis': sans-serif", padding: "1em" }}>
                        <Typography
                          variant="h6"
                          sx={{
                            
                            fontSize: "1em"
                          }}
                          style={{fontFamily: "'Dosis': sans-serif"}}
                        >
                          {t.text}
                        </Typography>
                      </Box>
                    );
                  } else if (t.label === "code") {
                    return (
                      <Box sx={{padding: "1em"}}>
                        <SyntaxHighlighter style={docco} language={t.language}>
                          {t.text}
                        </SyntaxHighlighter>
                      </Box>
                    );
                  } else if (t.label === "h4") {
                    return (
                      <Box sx={{ fontFamily: "'Dosis': sans-serif", padding: "1em" }}>
                        <Typography
                          variant="h4"
                          sx={{
                            
                            fontSize: "1em"
                          }}
                          style={{fontFamily: "'Dosis': sans-serif"}}
                        >
                          {t.text}
                        </Typography>
                      </Box>
                    );
                  }
                })}
                </>
            )}
        </>
     )
}